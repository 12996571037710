<template>
  <div class="bg-gradient-to-b from-white to-[#EFEFEF] pt-[95px] px-5 lg:px-8">
    <div class="max-w-7xl mx-auto">
      <div class="py-5 lg:py-12">
        <div class="flex flex-wrap-reverse lg:flex-nowrap justify-around">
          <div class="flex flex-col gap-5 justify-between">
            <p class="text-[#0CB1EF] md:text-black text-[14px] hidden lg:block">
              <a href="/" class="text-[#EC027C]">Home</a>
              <span class="text-[#EC027C]"> &nbsp;/&nbsp;</span>
              <a v-if="elective.program && elective.program.category" href="/" class="text-[#EC027C]">
                {{ elective.program.category.name }}
              </a>
              <span class="text-[#EC027C]"> &nbsp;/&nbsp;</span>
              <a v-if="elective.program" :href="'/' + elective.program.slug" class="text-[#EC027C]">
                {{ elective.program.name }}
              </a>
              <span class="text-[#EC027C]">&nbsp;/&nbsp;</span>
              <span class="text-[#4D4D4D]"> {{ elective.name }}</span>
            </p>
            <div class="flex flex-col">
              <!-- <h2
                class="text-[#EC027C] text-[18px] lg:text-[20px] font-semibold"
              >
                {{ elective.sub_name }}
              </h2> -->
              <h1 class="text-[#18479E] text-[30px] lg:text-[40px] font-medium">
                {{ elective.sub_name }}
                {{ elective.name }}
              </h1>
            </div>
            <div class="py-2">
              <div class="flex items-center gap-x-4">
                <div class="flex items-center gap-x-3">
                  <div>
                    <img src="@/assets/programm/NAAC_A+.webp" alt="NAAC_A+" />
                  </div>
                  <div class="text-sm lg:text-lg font-bold">
                    NAAC A+ accredited
                  </div>
                </div>
                <div class="flex items-center gap-x-3">
                  <div>
                    <img src="@/assets/programm/UGC_India_Logo.webp" alt="ugc_india" />
                  </div>
                  <div class="text-sm lg:text-lg font-bold">UGC-entitled</div>
                </div>
              </div>
            </div>
            <div class="flex gap-4">
              <div class="flex items-center gap-2">
                <div>
                  <img src="@/assets/programm/lastDate.png" alt="lastDate"
                    class="w-[18px] h-[18px] lg:w-[18px] lg:h-[18px]" />
                </div>
                <div class="text-[11px] font-bold text-black lg:hidden">
                  Limited <br />
                  seats available!
                </div>
                <div class="text-[16px] font-bold text-black hidden lg:block">
                  Limited seats available!
                </div>
              </div>
              <div class="flex items-center gap-2">
                <div>
                  <img src="@/assets/programm/limitedSeat.png" alt="lastDate"
                    class="w-[18px] h-[18px] lg:w-[18px] lg:h-[18px]" />
                </div>
                <div class="text-[11px] font-bold text-black lg:hidden">
                  Admission Open
                </div>
                <div class="text-[16px] font-bold text-black hidden lg:block">
                  Admission Open
                </div>
              </div>
            </div>
            <div class="flex gap-5">
              <a v-if="$route.path !== '/online-ba/political-science'" :href="apply_url" target="_blank" class="text-[#EC027C] text-[14px] lg:text-[16px] flex justify-center items-center font-bold border bg-[#FFFFFF] 
          rounded-full border-[#EC027C] w-[150px] lg:w-[160px] h-[50px]">
                Apply Now
              </a>

              <button v-if="$route.path !== '/online-ba/political-science'" @click="openDownloadPopup"
                class="text-[#EC027C] text-[14px] lg:text-[16px] font-bold border bg-[#FFFFFF] rounded-full border-[#EC027C] w-[150px] lg:w-[200px] h-[50px]">
                Download Brochure
              </button>

              <a v-if="$route.path === '/online-ba/political-science'"
                :href="'https://api.shardaonline.online/media/elective/brochures/BAHons-Political-Science-brochure_ly9yZcu.pdf'"
                target="_blank"
                class="text-[#EC027C] text-[14px] lg:text-[16px] font-bold border bg-[#FFFFFF] rounded-full border-[#EC027C] w-[150px] lg:w-[200px] h-[50px] flex items-center justify-center">
                Download Brochure
              </a>

            </div>
          </div>
          <div class="w-[380px] h-[360px]">
            <img :src="elective.banner" alt="bannerDesktop" v-if="elective.banner" />
            <img src="@/assets/elective/banner/petal@2x.png" alt="bannerdesk" v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--downloadbrochure modal-->
  <div class="Emodal" v-if="isDownloadPopupOpen">
    <div class="Emodal-content rounded-md relative w-full md:w-[35%] 2xl:w-[30%]">
      <button class="close rounded-full" @click="closeDownloadPopup">x</button>
      <downloadbrochureView :url="elective.brochures" />
    </div>
  </div>
  <!--downloadbrochure modal-->
</template>

<script>
import downloadbrochureView from "../enquireNowForms/downloadbrochure.vue";
export default {
  name: "BannerView",
  props: ["elective"],
  components: {
    downloadbrochureView
  },
  data() {
    return {
      isDownloadPopupOpen: false,
      apply_url: "https://apply.shardaonline.ac.in/"
    }
  },
  created() {
    if (this.$route.path == '/online-mcom/public-accounting') {

      this.apply_url = "https://apply.shardaonline.ac.in/mcom"

    }
    else if (this.$route.path == '/online-mba/sales-and-marketing') {

      this.apply_url = "https://apply.shardaonline.ac.in/mbsm"

    } else if (this.$route.path == '/online-pgd/banking-and-financial-services') {

      this.apply_url = "https://apply.shardaonline.ac.in/pgd"

    } else {
      this.apply_url = "https://apply.shardaonline.ac.in/"
    }
  },
  methods: {
    openDownloadPopup() {
      if (this.$route.path !== '/online-ba/political-science') {
        this.isDownloadPopupOpen = true;
      }
    },
    closeDownloadPopup() {
      this.isDownloadPopupOpen = false;
    }


  }
};
</script>

<style scoped>
.Emodal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2001;

  display: flex;
  justify-content: center;
  align-items: center;
}

.Emodal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border: 1px solid #888;
  max-width: 90%;
  width: 500px;
  margin: 0 20px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .Emodal-content {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .Emodal-content {
    width: 95%;
  }

  .close {
    font-size: 12px;
    width: 1.2rem;
    height: 1.2rem;
    top: 5px;
    right: 5px;
  }
}
</style>
