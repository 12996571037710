<template>
    <div class="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
      <div class="relative bg-white shadow-lg w-full md:w-[500px] h-[640px] rounded-lg">
        <button
            @click="closePopup"
          class="absolute top-2 right-2 p-2 bg-gray-200 rounded-full hover:bg-gray-300 focus:outline-none"
        >
          <svg class="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        
        <iframe v-if="widgetId && !loading && iframeUrl" :src="iframeUrl" width="100%" height="100%" frameborder="0" class="rounded-lg"></iframe>
        
        <div v-else-if="loading" class="flex flex-col items-center justify-center h-full p-8 space-y-4">
          <div class="relative w-16 h-16">
            <div class="absolute inset-0 rounded-full border-4 border-gray-300 border-t-blue-500 animate-spin"></div>
            <div class="absolute inset-2 rounded-full border-4 border-gray-200 border-b-blue-400 animate-spin animation-delay-150"></div>
          </div>
          <h3 class="text-lg font-medium text-gray-700 animate-pulse">Loading Enrollment Widget</h3>
          <p class="text-sm text-gray-500">Please wait while we connect you...</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "EnquireNow",
    data() {
      return {
        widgetId: null,
        loading: false,
        defaultWidgetId: "3bb56978-2b40-4cd0-9835-ba28b64fd472",
        partnerWidgets: [
          {
            partner_name: "Simandhar",
            widget_id: "7863bb23-1914-4d4d-a060-719fa8ddc9cc",
            website_url: "/online-mba/sales-and-marketing"
          },
          {
            partner_name: "TWOSS",
            widget_id: "69d416a1-f032-47d2-89a6-b83222a0fe2c",
            website_url: "/online-mcom/public-accounting"
          }
        ]
      };
    },
    computed: {
      iframeUrl() {
        return this.widgetId
          ? `https://widget.sharda.prod.umsglobal.net/#/?color=white&leadOwner=sharda_landing_page&leadSource=sharda_landing_page&leadOrigin=sharda_landing_page&academia_id=7A1FA08E-A78C-453E-8D0B-F394598A5922&university=sharda&widget_id=${this.widgetId}`
          : null;
      }
    },
    created() {
      this.loading = true;
      const currentPath = this.$route.path;
      console.log("Current Path:", currentPath);
      const partner = this.partnerWidgets.find(p => p.website_url === currentPath);
      this.widgetId = partner ? partner.widget_id : this.defaultWidgetId;
      this.loading = false;
    },
    methods: {
      closePopup() {
        console.log("Close button clicked");
        this.$emit('close');
      }
    }
  };
  </script>
  
  <style scoped>
  .animate-spin {
    animation: spin 1.5s linear infinite;
  }
  .animation-delay-150 {
    animation-delay: 0.15s;
  }
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  </style>
  