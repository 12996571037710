<template>
  <div>
    <section class="lg:block hidden">
      <div class="sidebar-call-holder">
        <a href="tel:09240013640"
          ><span class="custom-tooltip">+91-9240013640</span
          ><img
            alt="Call Icon"
            title="Call Icon"
            src="@/assets/stickyIcons/call.png"
            width="44"
            class=""
          />
        </a>
        <a
          href="https://api.whatsapp.com/send/?phone=918068212792&text&app_absent=0"
          target="_blank"
          ><img
            alt="whatsapp"
            title="Whatsapp Icon"
            src="@/assets/stickyIcons/whatsapp.png"
            width="44"
            class=""
          />
        </a>
        <!--<a
            ><span
              class="custom-tooltip -ml-12 gap-2 flex-col flex-wrap"
              style=""
              >Admission closing soon
               <span
                id="countdown"
                class="text-[#ce3b32] font-semibold text-xl"
                >{{ countdown }}</span
              > 
              </span
            ><img
              alt="call"
              src="../assets/application_closes_in_icon.webp"
              width="44"
            />
          </a>-->
      </div>
    </section>
    <section>
      <div class="fixed bottom-0 w-full">
        <div class="w-full md:hidden bg-white">
          <!--<div class="bg-[#0CB1EF] md:hidden z-20 w-full h-8">
            <span
              class="text-white font-bold z-50 text-center"
              style=""
              >Admission closing soon
              <span
                id="countdown1"
                class="text-[#2a2a2a] font-semibold text-xl"
                > {{ countdown1 }}</span
              > 
              </span
            >
          </div>-->
          <div class="flex justify-between px-3 py-3">
            <div class="rounded-lg">
              <a href="https://apply.shardaonline.ac.in/">
                <div class="flex flex-col justify-center items-center gap-3">
                  <img
                    src="@/assets/stickyIcons/SO- Mobileapply.png"
                    class="w-[41px] h-[41px]"
                    title="Enquire Now Mobile"
                    alt="Enquire Now Mobile"
                  />
                  <p class="text-black font-semibold text-[11px] uppercase">
                    Apply Now
                  </p>
                </div>
              </a>
            </div>
            <div class="border-r border-[#D1D1D1]"></div>
            <a
              href="https://api.whatsapp.com/send/?phone=918068212792&amp;text&amp;app_absent=0"
              target="_blank"
            >
              <div class="flex flex-col justify-center items-center gap-3">
                <img
                  src="@/assets/stickyIcons/SO-MobileBottomwhatsapp.png"
                  class="w-[41px] h-[41px]"
                  alt="whatsapp-icon"
                />
                <p class="text-black font-semibold text-[11px] uppercase">
                  Whatsapp
                </p>
              </div></a
            >
            <div class="border-r border-[#D1D1D1]"></div>
            <div class="rounded-lg">
              <div
                @click="openModal"
                class="flex flex-col justify-center items-center gap-3"
              >
                <img
                  src="@/assets/stickyIcons/SO-MobileBottomenquire.png"
                  class="w-[41px] h-[41px]"
                  title="Enquire Now Mobile"
                  alt="Enquire Now Mobile"
                />
                <p class="text-black font-semibold text-[11px] uppercase">
                  Enquire Now
                </p>
              </div>
            </div>
            <div class="border-r border-[#D1D1D1]"></div>
            <a href="tel:09240013640">
              <div class="flex flex-col justify-center items-center gap-3">
                <img
                  src="@/assets/stickyIcons/SO-MobileBottomcall.png"
                  class="w-[41px] h-[41px]"
                  title="Call Icon Mobile"
                  alt="Call Icon Mobile"
                />
                <p class="text-black font-semibold text-[11px] pt-1 uppercase">
                  Call Now
                </p>
              </div></a
            >
          </div>
        </div>
      </div>
    </section>
    <!-- Modal -->
    <div class="Emodal !z-50" v-if="isModalOpen">
      <div
        class="Emodal-content rounded-md relative w-full top-[100px] lg:top-[-17%] lg:bottom-24 md:w-[35%] 2xl:w-[30%]"
      >
        <button class="close rounded-full" @click="closeModal">x</button>
        <enquireView />
      </div>
    </div>
  </div>
</template>

<script>
import enquireView from "@/components/enquireNowForms/enquire.vue";
export default {
  name: "stickyView",
  components: {
    enquireView,
  },
  data() {
    return {
      countdown: "Loading...", // Initial value before the timer starts
      isModalOpen: false,
    };
  },
  methods: {
    getNeverEndingDate() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.setHours(0, 0, 0, 0);
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
  mounted() {
    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = this.countDownDate - now;

      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.countdown = `${hours}h ${minutes}m ${seconds}s`;
      this.countdown1 = `${hours}h ${minutes}m ${seconds}s`;

      if (distance < 0) {
        this.countDownDate = this.getNeverEndingDate();
      }
    };

    this.countDownDate = this.getNeverEndingDate();
    updateCountdown();
    setInterval(updateCountdown, 1000);
  },
};
</script>

<style scoped>
.sidebar-call-holder {
  position: fixed;
  top: 80%;
  right: 0;
  transform: translateY(-50%);
  z-index: 99;
  width: 60px;
}

.sidebar-call-holder1 {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 99;
  width: 60px;
}

.sidebar-call-holder a {
  margin-bottom: 5px;
  margin-left: 0;
  margin-top: 5px;
  display: inline-block;
  width: 100%;
  position: relative;
}

.sidebar-call-holder1 a {
  margin-bottom: 5px;
  margin-right: 0;
  margin-top: 5px;
  display: inline-block;
  width: 100%;
  position: relative;
}

span.custom-tooltip {
  padding: 8px 15px;
  white-space: nowrap;
  background: #e4e7ed;
  color: #7d7d7d;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
  border-radius: 10px;
  position: absolute;
  z-index: -1;
  left: -185px;
  top: 15px;
  display: flex;
  align-items: center;
  transform: translateX(162px);
  opacity: 0;
  transition: all 0.3s ease;
}
.sidebar-call-holder a:hover .custom-tooltip {
  transform: translateX(0px);
  opacity: 1;
  transition: all 0.3s ease;
}

.sidebar-call-holder1 a:hover .custom-tooltip {
  transform: translateX(0px);
  opacity: 1;
  transition: all 0.3s ease;
}
.custom-tooltip:before {
  content: "";
  position: absolute;
  right: -10px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  z-index: 9;
  width: 10px;
  border-left: 10px solid #e4e7ed;
  top: 11px;
}
.custom-tooltip .fa {
  margin-right: 5px;
  font-size: 20px;
}
a.fixed-call-btn {
  display: none;
  position: fixed;
  right: 5px;
  z-index: 99;
  bottom: 0;
}

.Emodal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Emodal-content {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  top: 50px;
}

.close {
  position: relative;
  color: white;
  float: right;
  top: -10px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  background-color: #18479E;
  width: 2rem;
  height: 2rem;
}

.close:hover {
  color: black;
}

@keyframes blink {
  0% {
    background-color: #18469d;
  }
  50% {
    background-color: transparent; /* or any other color you want during the blink */
  }
  100% {
    background-color: #18469d;
  }
}

.blinking-bg {
  animation: blink 1s infinite;
  background-color: #18469d; /* Set the initial background color */
  padding: 5px;
}
</style>
